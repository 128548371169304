import React from 'react';
import { useQueryParam } from 'gatsby-query-params';
import { SearchResults } from 'components/windrate';
import { SearchResultsHero } from 'components/windrate';
import { makeStyles } from '@material-ui/styles';

const data = require('content/global/searchdata.json');

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
}));

const CloudStorageSearchResults = () => {
  const location = useQueryParam("location", "any");
  const provider = useQueryParam("provider", "any");
  const classes = useStyles();

  return (
    <>
    <div className={classes.root}>
      <SearchResultsHero 
      location={location}
      provider={provider}    
      />
      <SearchResults 
      location={location}
      provider={provider}
      data={data}
      />
    </div>
    </>
  );
};

export default CloudStorageSearchResults;